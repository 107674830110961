<template>
  <span>
    <highcharts :options="chartOptions" :deepCopyOnUpdate="false" />
  </span>
</template>

<script>
  import columnChartMixin from "./columnChartMixin";

  export default {
    name: "ChartPrpPorTipologia",
    mixins: [columnChartMixin],
    props: {
      dadosPorTipologia: {
        type: Object,
        required: true,
      },
      dadosPorSubtipologia: {
        type: Object,
        required: true,
      },
      dadosSubTipologiasByTipologia: {
        type: Object,
        required: true,
      },
      traducaoTipologias: {
        type: Object,
        required: true,
      },
      traducaoSubTipologias: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      chartOptions: {},
    }),
    methods: {
      setChartOptions() {
        this.chartOptions = this.getColumnChartOptions(
          this.getPrpPorTipologiaRemainingChartOptions()
        );
      },
      getPrpPorTipologiaRemainingChartOptions() {
        return {
          xAxis: {
            type: "category",
            title: {
              text: "Tipologias",
            },
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: "{point.y:.2f}%",
              },
            },
          },
          series: [
            {
              tooltip: {
                headerFormat: "PRP<br>",
                pointFormat:
                  '<span style="color:{point.color}">{point.name}</span>: ' +
                  "<b>{point.y:.2f}%</b><br/>",
              },
              name: "Tipologias",
              animation: false,
              colorByPoint: true,
              data: Object.keys(this.dadosPorTipologia).map((tipologia) => {
                return {
                  name: this.traducaoTipologias[tipologia],
                  tipologia: tipologia,
                  drilldown: Object.keys(
                    this.dadosPorSubtipologia
                  ).some((subtipologia) => subtipologia.startsWith(tipologia))
                    ? tipologia
                    : null,
                  y: this.dadosPorTipologia[tipologia].prp,
                };
              }),
            },
          ],
          drilldown: {
            series: Object.keys(this.dadosSubTipologiasByTipologia)?.map(
              (tipologia) => {
                return {
                  name: this.traducaoTipologias[tipologia],
                  id: tipologia,
                  data: this.dadosSubTipologiasByTipologia[tipologia].map(
                    (dados) => {
                      return [
                        this.traducaoSubTipologias[dados.subtipologia],
                        dados.prp,
                      ];
                    }
                  ),
                };
              }
            ),
          },
        };
      },
    },
    watch: {
      dadosSubTipologiasByTipologia() {
        this.setChartOptions();
      },
    },
  };
</script>

<style></style>
